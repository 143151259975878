<script setup>
    import Layout from "@/layouts/main.vue";
    import PageHeader from "@/components/page-header";  
    import "@suadelabs/vue3-multiselect/dist/vue3-multiselect.css";
    import { required, helpers, email } from "@vuelidate/validators";
    import useVuelidate from "@vuelidate/core";
    import { computed, ref, reactive } from 'vue';
    import { useStore } from 'vuex';

    const state = reactive({
        name: '',
        code: null,
        token: null,
        no_contact_link: '',
        order_payment_api: '',
        skip_ovr: false,
        address: '',
        email: '',
        contact: '',
        basic_fee: '',
        abbreviation: '',
        description: '',
        remarks: '',
        enable_multiple_violation: true
    });

    const rules = {
        name: {
            required: helpers.withMessage("LGU Name is required", required),
        },
        code: {
            required: helpers.withMessage("LGU Code is required", required),
        },
        token: {
            required: helpers.withMessage("LGU Token is required", required),
        },
        no_contact_link: {
            required: helpers.withMessage("No Contact Link is required", required),
        },
        order_payment_api: {
            required: helpers.withMessage("Order Payment API is required", required),
        },
        address: {
            required: helpers.withMessage("Address is required", required),
        },
        email: {
             required: helpers.withMessage("Email is required", required),
             email: helpers.withMessage("Please enter valid email address", email)
        },
        contact: {
             required: helpers.withMessage("Contact Number is required", required),
        },
        basic_fee: {
             required: helpers.withMessage("Basic Fee is required", required),
        },
        abbreviation: {
             required: helpers.withMessage("Abbreviation is required", required),
        },
        description: {
             required: helpers.withMessage("Description is required", required),
        },
        remarks: {
             required: helpers.withMessage("Remarks is required", required),
        }
    }

    const store = useStore();
    const v$ = useVuelidate(rules, state);

    const title = ref("LGU");
    const items = ref([
        {
            text: "Home",
            href: "/",
        },
        {
            text: "Settings",
            href: "/settings/lgu/list",
            active: true
        },
        {
            text: "Add New LGU",
            active: true,
        }
    ]);

    const submitted = ref(false);
    const loading = computed(() => store.state.globals.loading);
    const notification = computed(() => store ? store.state.notification : null)

    const createLGU = () => {
        submitted.value = true
        v$.value.$touch();

        if (v$.value.$invalid) return;
        store.dispatch('lgu/createLGU', state);
    }
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
        <div class="col lg-12">
            <b-overlay :show="loading">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title mb-0">Add New LGU</h4>
                    </div>
                    <div class="card-body">
                        <div v-if="notification && notification.messages.length > 0">
                            <b-alert v-for="(message, index) in notification.messages" :key="index"
                                show variant="danger" class="fade show" role="alert">
                                {{ message }}
                            </b-alert>
                        </div>

                        <form @submit.prevent="createLGU" class="mx-2">
                            <div class="row">
                                <p class="fs-6 text text-decoration-underline">LGU Information</p>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="lgu-name" class="form-label required">LGU Name</label> 
                                        <input 
                                            autofocus
                                            :class="{ 'is-invalid': submitted && v$.name.$error }"
                                            v-model="state.name" class="form-control" type="text" placeholder="Enter LGU Name..." id="lgu-name" />
                                        <div
                                            v-for="item of v$.name.$errors"
                                            :key="item.$uid"
                                            class="invalid-feedback"
                                        >
                                            <span v-if="item.$message">{{ item.$message }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="lgu-code" class="form-label required">LGU Code</label> 
                                        <input 
                                            :class="{ 'is-invalid': submitted && v$.code.$error }"
                                            type="number"
                                            v-model="state.code" class="form-control" placeholder="Enter LGU Code..." id="lgu-code" />
                                        <div
                                            v-for="item of v$.code.$errors"
                                            :key="item.$uid"
                                            class="invalid-feedback"
                                        >
                                            <span v-if="item.$message">{{ item.$message }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="lgu-token" class="form-label required">LGU Token</label> 
                                        <input
                                            type="text"
                                            :class="{ 'is-invalid': submitted && v$.token.$error }"
                                            v-model="state.token" class="form-control" placeholder="Enter LGU Token..." id="lgu-token" />
                                        <div
                                            v-for="item of v$.token.$errors"
                                            :key="item.$uid"
                                            class="invalid-feedback"
                                        >
                                            <span v-if="item.$message">{{ item.$message }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="lgu-no-contact-link" class="form-label required">No Contact Link</label> 
                                        <input
                                            type="text"
                                            :class="{ 'is-invalid': submitted && v$.no_contact_link.$error }"
                                            v-model="state.no_contact_link" class="form-control" placeholder="Enter No Contact Link..." id="lgu-no-contact-link" />
                                        <div
                                            v-for="item of v$.no_contact_link.$errors"
                                            :key="item.$uid"
                                            class="invalid-feedback"
                                        >
                                            <span v-if="item.$message">{{ item.$message }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="lgu-order-payment-api" class="form-label required">Order Payment API</label> 
                                        <input
                                            type="text"
                                            :class="{ 'is-invalid': submitted && v$.order_payment_api.$error }"
                                            v-model="state.order_payment_api" class="form-control" placeholder="Enter Order Payment API..." id="lgu-order-payment-api" />
                                        <div
                                            v-for="item of v$.order_payment_api.$errors"
                                            :key="item.$uid"
                                            class="invalid-feedback"
                                        >
                                            <span v-if="item.$message">{{ item.$message }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="skip-ovr" class="form-label">Skip OVR?</label>
                                        <div class="form-check form-switch form-switch-lg" dir="ltr">
                                            <input type="checkbox" v-model="state.skip_ovr" class="form-check-input" id="skip-ovr" checked="">
                                            <label class="form-check-label" for="skip-ovr">{{ state.skip_ovr ? 'Yes' : 'No' }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="lgu-address" class="form-label required">Address</label> 
                                        <textarea
                                            :class="{ 'is-invalid': submitted && v$.address.$error }"
                                            v-model="state.address" class="form-control" placeholder="Enter Address..." id="lgu-address"></textarea>
                                        <div
                                            v-for="item of v$.address.$errors"
                                            :key="item.$uid"
                                            class="invalid-feedback"
                                        >
                                            <span v-if="item.$message">{{ item.$message }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="lgu-email" class="form-label required">Email</label> 
                                        <input
                                            type="text"
                                            :class="{ 'is-invalid': submitted && v$.email.$error }"
                                            v-model="state.email" class="form-control" placeholder="Enter Email..." id="lgu-email" />
                                        <div
                                            v-for="item of v$.email.$errors"
                                            :key="item.$uid"
                                            class="invalid-feedback"
                                        >
                                            <span v-if="item.$message">{{ item.$message }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="lgu-contact" class="form-label required">Contact Number</label> 
                                        <input
                                            type="text"
                                            :class="{ 'is-invalid': submitted && v$.contact.$error }"
                                            v-model="state.contact" class="form-control" placeholder="Enter Contact Number..." id="lgu-contact" />
                                        <div
                                            v-for="item of v$.contact.$errors"
                                            :key="item.$uid"
                                            class="invalid-feedback"
                                        >
                                            <span v-if="item.$message">{{ item.$message }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="lgu-basic-fee" class="form-label required">Basic Fee</label> 
                                        <input
                                            type="number"
                                            :class="{ 'is-invalid': submitted && v$.basic_fee.$error }"
                                            v-model="state.basic_fee" class="form-control" placeholder="Enter Basic Fee..." id="lgu-basic-fee" />
                                        <div
                                            v-for="item of v$.basic_fee.$errors"
                                            :key="item.$uid"
                                            class="invalid-feedback"
                                        >
                                            <span v-if="item.$message">{{ item.$message }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="lgu-abbreviation" class="form-label required">Abbreviation</label> 
                                        <input
                                            type="text"
                                            :class="{ 'is-invalid': submitted && v$.abbreviation.$error }"
                                            v-model="state.abbreviation" class="form-control" placeholder="Enter Abbreviation..." id="lgu-abbreviation" />
                                        <div
                                            v-for="item of v$.abbreviation.$errors"
                                            :key="item.$uid"
                                            class="invalid-feedback"
                                        >
                                            <span v-if="item.$message">{{ item.$message }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="lgu-description" class="form-label required">Description</label> 
                                        <textarea
                                            :class="{ 'is-invalid': submitted && v$.description.$error }"
                                            v-model="state.description" class="form-control" placeholder="Enter Description..." id="lgu-description"></textarea>
                                        <div
                                            v-for="item of v$.description.$errors"
                                            :key="item.$uid"
                                            class="invalid-feedback"
                                        >
                                            <span v-if="item.$message">{{ item.$message }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="lgu-remarks" class="form-label required">Remarks</label> 
                                        <textarea
                                            :class="{ 'is-invalid': submitted && v$.remarks.$error }"
                                            v-model="state.remarks" class="form-control" placeholder="Enter Remarks..." id="lgu-remarks"></textarea>
                                        <div
                                            v-for="item of v$.remarks.$errors"
                                            :key="item.$uid"
                                            class="invalid-feedback"
                                        >
                                            <span v-if="item.$message">{{ item.$message }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="enable-multiple-violation" class="form-label">Enable Multiple Violation?</label>
                                        <div class="form-check form-switch form-switch-lg" dir="ltr">
                                            <input type="checkbox" v-model="state.enable_multiple_violation" class="form-check-input" id="enable-multiple-violation" checked="">
                                            <label class="form-check-label" for="enable-multiple-violation">{{ state.enable_multiple_violation ? 'Yes' : 'No' }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="text-end">
                                        <router-link to="/settings/lgu/list" type="submit" class="btn btn-danger mr-3">
                                            <i class="ri-close-fill fs-4 mr-1 valign-middle"></i>
                                            Cancel
                                        </router-link>
                                        <button type="submit" class="btn btn-success">
                                            <i class="ri-save-2-fill fs-4 mr-1 valign-middle"></i>
                                            Add LGU
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </b-overlay>
        </div>
    </div>
  </Layout>
</template>